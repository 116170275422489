<template lang="pug">
  v-card-content
    .row
      .col-md-6
        .form-group
          label {{ 'ui.labels.profile_summaries' | translate }}
            v-info-icon(:text="$t('infotext.profile_summaries_info')")
          v-select(
            single
            :tabindex="1"
            :placeholder="'ui.dropdown.select_option' | translate "
            track-by="title"
            item-value="value"
            :skeleton="!loaded"
            :value="emailRemindersNewsPreference"
            :options="emailRemindersPreferencesOptions"
            @input="setEmailRemindersNewsPreference")
          span.form-text.invalid {{ 'news' | errorText }}

        .form-group
          label {{ 'ui.labels.validation_requests' | translate }}
            v-info-icon(:text="$t('infotext.validation_requests_info')")
          v-select(
            single
            :tabindex="3"
            :placeholder="'ui.dropdown.select_option' | translate "
            track-by="title"
            item-value="value"
            :skeleton="!loaded"
            :value="emailRemindersValidationsPreference"
            :options="emailRemindersPreferencesOptions"
            @input="setEmailRemindersValidationsPreference")
          span.form-text.invalid {{ 'validation' | errorText }}

      .col-md-6
        .form-group
          label {{ 'ui.labels.badge_suggestions' | translate }}
            v-info-icon(:text="$t('infotext.badge_suggestions_info')")
          v-select(
            single
            :tabindex="2"
            item-value="value"
            :placeholder="'ui.dropdown.select_option' | translate "
            track-by="title"
            :skeleton="!loaded"
            :value="emailRemindersSuggestionsPreference"
            :options="emailRemindersPreferencesOptions"
            @input="setEmailRemindersSuggestionsPreference")
          span.form-text.invalid {{ 'suggestions' | errorText }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { EMAIL_REMINDERS_PREFERENCES_OPTIONS } from '@/util/constants'

export default {
  name: 'EmailPreference',

  props: {
    userInfoForm: Object,
    isOwnProfileEditingPermitted: Boolean
  },

  async mounted () {
      Promise.all([
      this.getEmailRemindersNewsPreference(),
      this.getEmailRemindersSuggestionsPreference(),
      this.getEmailRemindersValidationsPreference()
    ]).then(() => {
      this.loaded = true
    })
  },

  data: () => ({
    loaded: false
  }),

  computed: {
    ...mapGetters('permissions', [
      'currentPermissions'
    ]),

    ...mapGetters('app', [
      'emailRemindersNewsPreference',
      'emailRemindersSuggestionsPreference',
      'emailRemindersValidationsPreference'
    ]),

    emailRemindersPreferencesOptions () {
      return EMAIL_REMINDERS_PREFERENCES_OPTIONS
    }
  },

  methods: {
    ...mapActions('app', [
      'getEmailRemindersNewsPreference',
      'getEmailRemindersSuggestionsPreference',
      'getEmailRemindersValidationsPreference',
      'setEmailRemindersNewsPreference',
      'setEmailRemindersSuggestionsPreference',
      'setEmailRemindersValidationsPreference'
    ])
  }
}
</script>

<style lang="scss" scoped>
.form-group > label {
  color: $body-text;
  font-weight: 400;
}
</style>
